import { FIELDS } from "lib"

export const TAXPLAN_TABLE_MAIN_COLUMNS = [
    { id: FIELDS.taxCode.key, label: FIELDS.taxCode.name },
    { id: FIELDS.description.key, label: FIELDS.description.name },
    { id: "concepto", label: "Concepto" },
    { id: FIELDS.active.key, label: FIELDS.state.name },
]

export const TAXPLAN_TABLE_RETENTION_COLUMNS = [
    { id: FIELDS.retentionType.key, label: FIELDS.retentionType.name },
    { id: FIELDS.retentionCode.key, label: FIELDS.retentionCode.name },
    { id: FIELDS.retentionDescription.key, label: FIELDS.retentionDescription.name },
    { id: FIELDS.retentionPercentage.key, label: FIELDS.retentionPercentage.name },
]