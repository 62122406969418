import { Button, Paper, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { FileUploadDialog, FixedLoadingIndicator } from "components"
import { AUXILIAR_TITLE, ROOT_TITLE } from "lib"
import { uploadExcelFile } from "lib/utils/api/excelfile"
import React from "react"
import { Helmet } from "react-helmet"
import { IMessageConfig } from "types"

const ExcelRetentionPage = () => {

    const [openDialog, setOpenDialog] = React.useState<boolean>(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    const handleUpload = async (file: File) => {
        try {

            const status = await uploadExcelFile(file)
            setOpenDialog(false)
            if (status === 200) {
                setMessageConfig({ open: true, message: "El archivo cargado será procesado a continuación.", severity: "info" })
            }
            if (status === 409) {
                setMessageConfig({ open: true, message: "Existe en este momento un archivo procesándose....", severity: "warning" })
                return
            }
        } catch (error) {
            setOpenDialog(false)
            setMessageConfig({ open: true, message: "El archivo cargado no pudo ser procesado.", severity: "error" })
        }
    }

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${AUXILIAR_TITLE}`}</title>
            </Helmet>
            <div className="flex items-center justify-between">
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                        setOpenDialog(true)
                    }}
                >
                    {"Subir Archivo Excel"}
                </Button>
            </div>
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>

            <FileUploadDialog
                open={openDialog}
                onClose={() => { setOpenDialog(false) }}
                onAccept={(files) => { handleUpload(files[0].file) }}
                singleFile
                hideDescription
            />
        </Paper>
    )
}

export default ExcelRetentionPage