import { Typography, Box, ButtonGroup, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Icon, IconButton, Tooltip, Divider, Snackbar, CircularProgress, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { RejectionDialog, SendMailDialog } from 'components'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import ApproveDocumentDialog from 'components/ApproveDocumentDialog/ApproveDocumentDialog'
import { AuthContext, DocumentContext } from 'context'
import FileSaver from 'file-saver'
import { DASHBOARD_PATH, DOCUMENT_ACTIONS, DOCUMENT_PATH, DOCUMENT_STATES, getDocumentActions, approveDocumentService, downloadPdfByDocumentFileId, assignPurchaseOrderService, DOCUMENT_TYPE_CODES, rejectDocumentService, DOCUMENT_TYPE_IDS, DOCUMENT_MANAGE_ACTION_ID, updateDocumentStatusService, ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS, DOCUMENT_APPROVE_MANUALLY_ACTION_ID, DOCUMENT_REJECT_MANUALLY_ACTION_ID, DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID, wsGetEmailsFromSupplier, wsResendEmailsFromRetention } from 'lib'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { IDocumentAction, IDocumentAdditionalInfo, IDocumentHeader, IMessageConfig, IPurchaseOrderAccoutingInfo, IRejectionCause, ITaxPlan } from 'types'

interface Props {
    processStatus: number
    documentType?: string
    supplierId?: number
    documentId?: number
    documentData: any
    onReload: () => void
    isManual?: boolean
    header: IDocumentHeader
    documentTypeId: number
    redirectOnApprove?: string
    redirectOnReject?: string
    aditionalInfo: any
    validateReimbusements?: boolean
}

const DocumentBar = (props: Props) => {
    const { processStatus, documentType, supplierId, documentId, documentData, onReload, isManual, header, documentTypeId, redirectOnApprove, aditionalInfo, validateReimbusements, redirectOnReject } = props
    const history = useHistory()
    const { conditions, refreshConditions, documentTypes } = React.useContext(DocumentContext)
    const { user } = React.useContext(AuthContext)
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef<HTMLDivElement>(null)
    const [selected, setSelected] = React.useState<IDocumentAction | undefined>(undefined)
    const [openRejectionDialog, setOpenRejectionDialog] = React.useState(false)
    const [openApproval, setOpenApprovalDialog] = React.useState(false)
    const [openPending, setOpenPending] = React.useState(false)
    const [openSend, setOpenSend] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })

    // DIALOGO DE CORREOS DE RETENCIONES
    const [openDialogMail, setOpenDialogMail] = React.useState(false);
    const [mails, setmails] = React.useState('')
    const handleClickOpenDialogMail = () => {
        consultarCorreos()
        setOpenDialogMail(true);
    };
    const handleCloseDialogMail = () => {
        setOpenDialogMail(false);
    };
    const consultarCorreos = async () => {
        try {
            setLoading(true)
            const response = await wsGetEmailsFromSupplier(documentId)
            setmails(response)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo obtener correos del proveedor seleccionado", severity: "error" })
            setLoading(false)
        }
    }
    const reenviarCorreo = async () => {
        try {
            setLoading(true)
            await wsResendEmailsFromRetention(documentId)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setMessageConfig({ open: true, message: "No se pudo enviar el correo electronico", severity: "error" })
            setLoading(false)
        }
        setOpenDialogMail(false);
    }
    // FIN DIALOGO DE CORREOS DE RETENCIONES

    // DIALOGO DE RECHAZO DE FACTURAS
    const [openDialogRechazoInvoice, setOpenDialogRechazoInvoice] = React.useState(false);
    const handleClickOpenDialogRechazoInvoice = () => {
        setOpenDialogRechazoInvoice(true);
    };
    const handleCloseDialogRechazoInvoice = () => {
        setOpenDialogRechazoInvoice(false);
    };
    const rechazarFactura = async () => {
        setOpenRejectionDialog(true)
    }
    const handleAnulation = async () => {
        try {
            setLoading(true)
            await rejectDocumentService(
                {
                    documentId,
                    userId: user?.userId,
                    statusId: DOCUMENT_STATES.rejected
                }, null
            )
            setMessageConfig({ open: true, message: "Documento anulado!", severity: "success" })
            onReload()
            setLoading(false)
            handleCloseDialogRechazoInvoice()
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo anular el documento...", severity: "error" })
            setLoading(false)
            handleCloseDialogRechazoInvoice()
        }
    }
    // FIN DIALOGO DE CORREOS DE RETENCIONES




    const handleClick = () => {
        if (!selected) {
            setMessageConfig({ open: true, message: "Selecciona una acción", severity: "warning" })
            return
        }
        if (selected?.id === DOCUMENT_ACTIONS.reject.id) {
            setOpenRejectionDialog(true)
            return
        }
        if (selected?.id === ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS.ignore.id) {
            handleIgnore()
            return
        }
        // if (selected?.id === DOCUMENT_ACTIONS.approve.id && (
        //     documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement || documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement 
        //     || documentTypeId === DOCUMENT_TYPE_IDS.creditNote || documentTypeId === DOCUMENT_TYPE_IDS.printedCreditNote)) {
        //     handleDirectApprove()
        //     return
        // }
        // if (selected?.id === DOCUMENT_ACTIONS.approve.id) {
        //     if (validateReimbusements !== undefined && !validateReimbusements) {
        //         setMessageConfig({ open: true, message: "Los valores enviados a COUPA y datos de las facturas de sustento no coiciden", severity: "error" })
        //         return
        //     }
        //     setOpenApprovalDialog(true)
        // }
        if (selected?.id === DOCUMENT_MANAGE_ACTION_ID
            || selected?.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID
            || selected?.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID
            || selected?.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
            setOpenSend(true)
        }
    }

    const handleIgnore = async () => {
        try {
            setLoading(true)
            await updateDocumentStatusService(documentId, ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS.ignore.id, user?.userId)
            setMessageConfig({ open: true, message: "El documento se ha cambiado de estado!", severity: "success" })
            onReload()
            setLoading(false)
            if (redirectOnApprove) {
                setTimeout(() => { history.replace(redirectOnApprove) }, 1500)
            }
        } catch (error) {
            setLoading(false)
            setMessageConfig({ open: true, message: "No se pudo cambiar el estado del documento...", severity: "error" })
        }
    }

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, action: IDocumentAction) => {
        if (action.id === DOCUMENT_MANAGE_ACTION_ID
            || action.id === DOCUMENT_APPROVE_MANUALLY_ACTION_ID
            || action.id === DOCUMENT_REJECT_MANUALLY_ACTION_ID
            || action.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID) {
            setSelected(action)
            setOpenSend(true)
        } else {
            setSelected(action)
        }
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    const handleDownload = async () => {
        try {
            setLoading(true)
            const result = await downloadPdfByDocumentFileId(documentId)
            FileSaver.saveAs(result, `${documentType}_${documentId}.pdf`)
            setMessageConfig({ open: true, message: "Su descarga ha comenzado", severity: "info" })
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const handleDirectApprove = async () => {
        try {
            setLoading(true)
            await approveDocumentService(
                {
                    documentId,
                    userId: user?.userId,
                    statusId: DOCUMENT_STATES.authorized
                },
                isManual ?
                    { ...documentData, } :
                    { documentData: {} }
            )
            setMessageConfig({ open: true, message: "Documento aprobado!", severity: "success" })
            onReload()
            setLoading(false)
            if (redirectOnApprove) {
                setTimeout(() => { history.replace(redirectOnApprove) }, 1500)
            }
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo aprobar el documento...", severity: "error" })
            setLoading(false)
        }
    }

    const handleApprove = async (taxPlan?: ITaxPlan, purchaseOrder?: IPurchaseOrderAccoutingInfo) => {
        try {
            setLoading(true)
            await approveDocumentService(
                {
                    documentId,
                    userId: user?.userId,
                    statusId: DOCUMENT_STATES.authorized
                },
                isManual ?
                    {
                        ...documentData,
                        taxPlan,
                        accountInfo: { ...documentData.accountInfo, purchaseOrder },
                    } :
                    {
                        documentData: {},
                        taxPlan,
                        accountInfo: { ...documentData.accountInfo, purchaseOrder },
                        reimbursements: documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice ? JSON.parse(aditionalInfo).reimbursements : documentData.reimbursements
                    }
            )
            if (purchaseOrder?.documentId) {
                await assignPurchaseOrderService(purchaseOrder?.documentId, user?.userId, documentId)
            }
            setMessageConfig({ open: true, message: "Documento aprobado!", severity: "success" })
            onReload()
            setLoading(false)
            if (redirectOnApprove) {
                setTimeout(() => { history.replace(redirectOnApprove) }, 1500)
            }
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo aprobar el documento...", severity: "error" })
            setLoading(false)
        }
    }

    const handleReject = async (rejectionCause: IRejectionCause) => {
        try {
            setLoading(true)
            await rejectDocumentService(
                {
                    documentId,
                    userId: user?.userId,
                    statusId: DOCUMENT_STATES.rejected
                },
                isManual ?
                    {
                        ...documentData,
                        rejectionCause
                    } : {
                        ...JSON.parse(aditionalInfo),
                        rejectionCause
                    }
            )
            setMessageConfig({ open: true, message: "Documento rechazado!", severity: "success" })
            onReload()
            setLoading(false)
            if (redirectOnReject) {
                setTimeout(() => { history.replace(redirectOnReject) }, 1500)
            }
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo rechazar el documento...", severity: "error" })
            setLoading(false)
        }
    }

    React.useEffect(() => {
        refreshConditions()
    }, [refreshConditions])



    return (
        <div className="flex py-3 pr-8 items-center pl-4" >
            <div className="flex flex-grow items-center">
                <Tooltip arrow title="Regresar">
                    <IconButton
                        onClick={() => history.action !== "POP" ? history.goBack() : history.push(`${DOCUMENT_PATH}${DASHBOARD_PATH}`)}
                    >
                        <Icon fontSize="small">
                            chevron_left
                        </Icon>
                    </IconButton>
                </Tooltip>
                <div className="ml-3 mr-6" style={{ height: 20 }}>
                    <Divider orientation="vertical" />
                </div>
                <Icon fontSize="small" color="action">
                    {"check"}
                </Icon>
                <div className="flex flex-col pl-4">
                    <Typography variant="caption" color="textSecondary" style={{ fontWeight: 600, letterSpacing: 0.5 }}>
                        {documentType ?? ""}
                    </Typography>
                    <Box color="info.main">
                        <Typography variant="caption" style={{ fontWeight: 600, letterSpacing: 0.5, fontSize: "0.7em" }}>
                            {conditions.find(c => c.conditionId === processStatus)?.name}
                        </Typography>
                    </Box>
                </div>
            </div>
            <div className="flex items-center">
            {documentType === 'COMPROBANTE DE RETENCIÓN' &&
                    <>
                        <Button
                            disabled={loading}
                            size="small"
                            onClick={handleDownload}
                            style={{ marginRight: 15 }}
                            color="primary"
                            disableElevation
                            variant="contained"
                        >
                            {"Descargar pdf"}
                        </Button>
                    </>
                }
                {documentType === 'COMPROBANTE DE RETENCIÓN' && processStatus !== DOCUMENT_STATES.rejected &&
                    <>
                        <Button
                            disabled={loading}
                            size="small"
                            onClick={handleClickOpenDialogMail}
                            style={{ marginRight: 15 }}
                            color="primary"
                            disableElevation
                            variant="outlined"
                        >
                            {"Reenviar MAIL"}
                        </Button>
                        <Button
                            disabled={loading}
                            size="small"
                            onClick={handleClickOpenDialogRechazoInvoice}
                            style={{ marginRight: 15 }}
                            color="primary"
                            disableElevation
                            variant="outlined"
                        >
                            {"Anular Retencion"}
                        </Button>
                    </>
                }
                {documentType === 'FACTURA ELECTRÓNICA' && processStatus === DOCUMENT_STATES.pending &&
                    <>
                        <Button
                            disabled={loading}
                            size="small"
                            onClick={rechazarFactura}
                            style={{ marginRight: 15 }}
                            color="primary"
                            disableElevation
                            variant="contained"
                        >
                            {"RECHAZAR"}
                        </Button>
                        
                    </>
                }
                {/* {
                    processStatus !== DOCUMENT_STATES.finished && documentTypeId !== DOCUMENT_TYPE_IDS.employeeReimbursement &&
                    <ButtonGroup disableElevation size="small" variant="contained" color={selected ? "primary" : "default"} ref={anchorRef} aria-label="split button">
                        <Button
                            style={{ width: 180 }}
                            onClick={() => !Boolean(user?.canApprove || user?.canReject) ? undefined : handleClick()}
                            disabled={loading}
                            disableTouchRipple={!Boolean(user?.canApprove || user?.canReject)}
                        >
                            {loading ? <CircularProgress color="primary" size={15} thickness={10} /> : selected ? selected.name : conditions.find(c => c.conditionId === processStatus)?.name ?? ""}
                        </Button>
                        {
                            Boolean(user?.canApprove || user?.canReject) &&
                            <Button
                                disabled={loading}
                                size="small"
                                onClick={handleToggle}
                            >
                                <Icon fontSize="small">arrow_drop_down</Icon>
                            </Button>
                        }
                    </ButtonGroup>
                }
                {
                    processStatus !== DOCUMENT_STATES.finished && documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement &&
                    <ButtonGroup disableElevation size="small" variant="contained" color={selected ? "primary" : "default"} ref={anchorRef} aria-label="split button">
                        <Button
                            style={{ width: 180 }}
                            onClick={() => handleClick()}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress color="primary" size={15} thickness={10} /> : selected ? selected.name : conditions.find(c => c.conditionId === processStatus)?.name ?? ""}
                        </Button>
                        <Button
                            disabled={loading}
                            size="small"
                            onClick={handleToggle}
                        >
                            <Icon fontSize="small">arrow_drop_down</Icon>
                        </Button>
                    </ButtonGroup>
                } */}
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper style={{ width: 220 }} >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList dense id="split-button-menu">
                                        {Boolean(user?.canApprove || user?.canReject) && (getDocumentActions(documentTypeId)).map((option) => (
                                            <React.Fragment>
                                                {Boolean(user ? user[option.key] : false) &&
                                                    <MenuItem
                                                        dense
                                                        key={option.name}
                                                        onClick={(event) => handleMenuItemClick(event, option)}
                                                        classes={{ root: "button-list-menu-item" }}
                                                        selected={option.id === selected?.id}
                                                        style={{ padding: 0 }}
                                                    >
                                                        <div className="flex items-center h-full w-full py-1 pr-2 pl-1">
                                                            <Typography className="flex-grow pl-2" variant="caption" style={{ letterSpacing: 0.2 }}>
                                                                {option.name}
                                                            </Typography>
                                                            <Icon color="inherit" fontSize="small">{option.icon}</Icon>
                                                        </div>
                                                    </MenuItem>
                                                }
                                            </React.Fragment>
                                        ))}
                                        {documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement &&
                                            !Boolean(user?.canApprove || user?.canReject) && (getDocumentActions(documentTypeId))
                                                .filter((option) => option.id === DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID)
                                                .map((option) => (
                                                    <React.Fragment>
                                                        <MenuItem
                                                            dense
                                                            key={option.name}
                                                            onClick={(event) => handleMenuItemClick(event, option)}
                                                            classes={{ root: "button-list-menu-item" }}
                                                            selected={option.id === selected?.id}
                                                            style={{ padding: 0 }}
                                                        >
                                                            <div className="flex items-center h-full w-full py-1 pr-2 pl-1">
                                                                <Typography className="flex-grow pl-2" variant="caption" style={{ letterSpacing: 0.2 }}>
                                                                    {option.name}
                                                                </Typography>
                                                                <Icon color="inherit" fontSize="small">{option.icon}</Icon>
                                                            </div>
                                                        </MenuItem>
                                                    </React.Fragment>
                                                ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <Dialog
                    open={openDialogMail}
                    onClose={handleCloseDialogMail}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Envio de Retencion"}
                    </DialogTitle>
                    <DialogContent>
                        {mails === '' ?
                        <DialogContentText id="alert-dialog-description">
                            {'El proveedor no tiene correos electronicos asignados. Por favor ingresarlos desde la ventana de adminsitración de proveedores'}
                        </DialogContentText>
                        : 
                        <>
                        <DialogContentText id="alert-dialog-description">
                            {'El correo electronico de la retención sera enviado a las direcciones de correo encontradas en el proveedor: '}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description" style={{marginLeft:'30px', marginTop:'20px'}}>
                            {mails.replaceAll(";"," , ")}
                        </DialogContentText>
                        <br></br>
                        <DialogContentText id="alert-dialog-description" style={{fontSize:'13px', fontStyle:'oblique', fontWeight:'bold'}}>
                            {'Para agregar o eliminar correos electronicos realizarlo desde la ventana de adminsitración de proveedores'}
                        </DialogContentText>
                        </>
}
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '10px' }}>
                        <Button onClick={handleCloseDialogMail}>Cancelar</Button>
                        <Button color="primary" variant='contained' onClick={reenviarCorreo}>Enviar</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialogRechazoInvoice}
                    onClose={handleCloseDialogRechazoInvoice}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Anulación de retención"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'¿ Esta seguro que desea anular la retención? Este proceso anulara las retenciones asociadas, asi como rechazara la factura asociada.'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ marginBottom: '10px' }}>
                        <Button onClick={handleCloseDialogRechazoInvoice}>Cancelar</Button>
                        <Button color="primary" variant='contained' onClick={handleAnulation}>Anular</Button>
                    </DialogActions>
                </Dialog>
            </div>
            {header && documentData &&
                <ApproveDocumentDialog
                    open={openApproval}
                    onClose={() => setOpenApprovalDialog(false)}
                    onApprove={handleApprove}
                    supplierId={supplierId}
                    requirePo={Boolean((documentData as IDocumentAdditionalInfo)?.header?.supplier?.requirePo) && !Boolean((documentData as IDocumentAdditionalInfo).accountInfo?.purchaseOrder)}
                    requireTaxPlan={!Boolean((documentData as IDocumentAdditionalInfo)?.taxPlan)}
                    identification={header.supplier?.identification}
                    purchaseOrder={documentData.accountInfo?.purchaseOrder}
                    withReimbursementTaxPlan={Boolean(documentTypes.find(d => d.documentCode === DOCUMENT_TYPE_CODES.reimbursementInvoice)?.documentTypeId === documentTypeId)}
                />
            }
            <RejectionDialog
                open={openRejectionDialog}
                onClose={() => setOpenRejectionDialog(false)}
                onReject={handleReject}
            />
            <ConfirmDialog
                open={openPending}
                onCancel={() => setOpenPending(false)}
                onConfirm={async () => true}
                title={"¿Estas seguro de dejar pendiente este documento?"}
            />
            <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                    {messageConfig.message}
                </Alert>
            </Snackbar>
            {header &&
                <SendMailDialog
                    open={openSend}
                    onClose={() => setOpenSend(false)}
                    supplier={header.supplier}
                    onSend={() => setMessageConfig({ severity: 'success', message: "Correo enviado!", open: true })}
                    onError={() => setMessageConfig({ severity: 'error', message: "No se pudo enviar el correo...", open: true })}
                    noEmailsMessage={() => setMessageConfig({ severity: 'warning', message: "Agregar al menos un destinatario para continuar", open: true })}
                    invoiceNumber={header.invoiceNumber}
                    documentId={documentId ?? -1}
                    selectedAction={selected}
                    documentData={documentData}
                />
            }
        </div>
    )
}

export default DocumentBar
