import { Paper, Typography, Divider } from '@material-ui/core'
import React from 'react'
import { IDocument, IDocumentHeader, IExcelLine } from 'types'
import moment from 'moment'
import 'moment/locale/es'
import { FIELDS } from 'lib'

moment.locale("es")

interface Props {
    document: IDocument,
    lines: IExcelLine[]
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const DocumentDataFacturaVNS = (props: Props) => {
    const { document, lines } = props
    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {"DATOS DEL DOCUMENTO"}
            </Typography>
            <div className="mt-2">
                <div className="my-2">
                    <Divider />
                </div>
                {
                    document.serialNumber &&
                    <div className="flex mb-3 items-center" style={{marginBottom:'0px'}}>
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Código de Referencia:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {`${document.serialNumber}`}
                        </Typography>
                    </div>
                }
                {
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {`Header Text:`}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {lines && lines.length>0 && lines[0].headerText}
                        </Typography>
                    </div>
                }
                {
                    document.documentDate &&
                    <div className="flex">
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                            {FIELDS.emissionDate.name}
                        </Typography>
                        <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {moment(new Date(document.documentDate).toISOString()).format('DD-MM-YYYY').toString()}
                        </Typography>
                    </div>
                }
            </div>
        </Paper>
    )
}

export default DocumentDataFacturaVNS
