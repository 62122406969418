import { Paper, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { Rowing } from '@material-ui/icons'
import { ManagementContext } from 'context'
import { formatCurrency } from 'lib'
import { VensecarRetentionModel } from 'model/VensecarRetentionModel'
import React from 'react'
import { IExcelLine, IRetention } from 'types'

interface Props {
    lines: IExcelLine[],
    retentions: VensecarRetentionModel[]
}

const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }

const ExcelLineComponent = (props: Props) => {

    const { lines, retentions } = props
    const { taxTypes } = React.useContext(ManagementContext)

    const filteredLinesIVA = lines.filter((line) => line.withholdingTaxType === 'RI');
    const filteredLinesRENTA = lines.filter((line) => line.withholdingTaxType === 'A1');

    const filteredFileIVA =  retentions !== null ? retentions.filter((ret) => ret.ivaDetails.length > 0) : null;
    const filteredFileRENTA = retentions !== null ? retentions.filter((ret) => ret.rentaDetails.length > 0) : null;

    const checkDobleAsterisco = () => {
        if (retentions !== undefined && retentions !== null) {
            if (retentions.length > 0) {
                const retention: VensecarRetentionModel = retentions[0]
                if (retention.basesExcentas !== undefined && retention.basesExcentas !== null) {
                    return true
                }
            }
        }
        return false;
    }


    return (
        <div className="px-8 w-full py-4">
            {retentions !== null &&
                <Paper variant="outlined" className="mt-3 w-full" elevation={1}>

                    {filteredLinesIVA.length > 0 &&
                        <>
                            <div className="py-3 px-4 flex w-full items-center">
                                <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                    {"LINEAS"}
                                </Typography>
                            </div>
                            <Divider />
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={headerCellStyle} align="left">{"Descripcion"}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{"IVA 16%"}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{"Base imponible"}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{"Base 16%"}</TableCell>
                                            <TableCell style={headerCellStyle} align="left">{"Base Excenta"}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {filteredLinesIVA.map(line => (
                                                <>
                                                    <TableCell style={bodyCellStyle} align="left">{line.itemText}</TableCell>
                                                </>
                                            ))
                                            }
                                            {filteredFileIVA != null && filteredFileIVA.map(fileIva => (
                                                <>
                                                    <TableCell style={bodyCellStyle} align="left">{fileIva.ivaDetails[0].impuestoIVA}</TableCell>
                                                    <TableCell style={bodyCellStyle} align="left">{fileIva.ivaDetails[0].baseImponible}</TableCell>
                                                </>
                                            ))}
                                            {checkDobleAsterisco() &&
                                                <>
                                                    <TableCell style={bodyCellStyle} align="left">{retentions[0].basesExcentas.base16}</TableCell>
                                                    <TableCell style={bodyCellStyle} align="left">{lines[0].batvo}</TableCell>
                                                </>
                                            }
                                            {!checkDobleAsterisco() &&
                                                <>
                                                    <TableCell style={bodyCellStyle} align="left">{'--'}</TableCell>
                                                    <TableCell style={bodyCellStyle} align="left">{lines[0].batvo}</TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                </Paper>
            }
        </div>
    )
}

export default ExcelLineComponent
