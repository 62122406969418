import { Alert, AlertTitle } from "@material-ui/lab"
import React from "react"
import { IRejectionCause } from "types"

interface Props {
    rejectionCause?: IRejectionCause
    status: number
    type: number
}

const RejectionData = (props: Props) => {
    const { rejectionCause, status, type } = props
    React.useLayoutEffect(() => {
      console.log(rejectionCause);
    }, [])
    return (
        <React.Fragment>
            {
                rejectionCause &&
                <Alert className="w-full" severity="error" variant="outlined">
                    <AlertTitle>{`Motivo ${type === 7 ? "de anulación" : "de rechazo"}`}</AlertTitle>
                    {rejectionCause.rejectionCauseCode}<strong>{` - ${rejectionCause.name}`}</strong>
                    <br/>{rejectionCause.description !== undefined ? rejectionCause.description : ""}
                </Alert>
            }
        </React.Fragment>
    )
}

export default RejectionData
