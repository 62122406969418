import { ROOT_PATH, ERROR_PATH, FORBIDDEN_PATH } from 'lib'
import { Login, Forbidden } from 'pages'
import React from 'react'
import { Route } from 'react-router-dom'

const Public = () => {
    return (
        <React.Fragment>
            <Route exact path={`${ROOT_PATH}`} component={Login} />
            <Route exact path={`${ERROR_PATH + FORBIDDEN_PATH}`} component={Forbidden} />
        </React.Fragment>
    )
}

export default Public
