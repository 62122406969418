import { Paper, Typography, Divider, Box } from '@material-ui/core'
import { ManagementContext } from 'context'
import React from 'react'
import { IDocument, IDocumentHeader } from 'types'

interface Props {
    document: IDocument
}

const textStyle = { fontWeight: 600, fontSize: "0.8em" }

const EmissionDataFacturaVNC = (props: Props) => {
    const { document } = props
    const { identificationTypes, refreshIdentificationTypes } = React.useContext(ManagementContext)

    React.useEffect(() => {
        refreshIdentificationTypes()
    }, [refreshIdentificationTypes])

    return (
        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                {"DATOS DEL EMISOR"}
            </Typography>
            <div className="mt-2">
                <div className="my-2">
                    <Divider />
                </div>
                {
                    document &&
                    <React.Fragment>
                        <div className="flex mb-3 items-center" style={{marginBottom:'0px'}}>
                        <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                {'Razón Social:'}
                            </Typography>
                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                {document.companyName ? `${document.companyName}` : "SIN NOMBRE"}
                            </Typography>
                        </div>
                        <div className="flex">
                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                {`Identificación:`}
                            </Typography>
                            <Typography style={textStyle} className="pl-2" variant="subtitle2">
                            {document.companyRuc ? `${document.companyRuc}` : "SIN IDENTIFICACION"}
                            </Typography>
                        </div>
                        <div className="flex">
                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                {`Dirección:`}
                            </Typography>
                            <Typography style={textStyle} className="pl-2" variant="subtitle2">
                                {"DIRECCION NO REGISTRADA"}
                            </Typography>
                        </div>
                    </React.Fragment>
                }
                <div className="my-2"></div>
            </div>
        </Paper>
    )
}

export default EmissionDataFacturaVNC
