import { FIELDS } from "lib"
import { IDocumentFilter, IDocumentTypeInputs } from "types/document"
import { DOCUMENT_PATH } from "./paths"

export const DOCUMENT_TYPES: IDocumentTypeInputs[] = [
    {
        name: "FACTURA FISCAL DE COMPRA PREIMPRESA",
        route: `${DOCUMENT_PATH}`,
        type: "printed-invoice",
        inputs: {
            invoiceNumber: true,
            authorizationNumber: true,
            emissionDate: true,
            expiresDate: true,
            receptionDate: true,
            supplier: true,
            purchaseOrder: false,
            operationalReference: true,
            file: true
        },
        requireAccountInfo: true,
        withDetail: true
    },
    {
        name: "LIQUIDACIÓN DE GASTOS EXTERIOR",
        route: `${DOCUMENT_PATH}`,
        type: "foreign-expenses-liquidation",
        inputs: {
            invoiceNumber: false,
            documentNumber: true,
            authorizationNumber: false,
            emissionDate: true,
            expiresDate: false,
            receptionDate: true,
            supplier: true,
            purchaseSettlement: false,
            operationalReference: true,
            file: true
        },
        supplierCallback: false,
        requireAccountInfo: true,
        withDetail: true,
        rate12TaxOnly: false
    },
    {
        name: "REPOSICIÓN DE CAJA CHICA",
        route: `${DOCUMENT_PATH}`,
        type: "cash-reimbursement",
        inputs: {
            invoiceNumber: false,
            documentNumber: false,
            emissionDate: true,
            supplier: true,
            operationalReference: false,
            totalOtherDocuments: true,
            totalInvoices: true,
            advance: true
        },
        withDetail: false,
        withReimbursement: true,
        withReimbursementAndDetails: true,
        requireAccountInfo: false,
    },
    {
        name: "REEMBOLSO DE EMPLEADOS",
        route: `${DOCUMENT_PATH}`,
        type: "employee-reimbursement",
        inputs: {
            invoiceNumber: false,
            documentNumber: false,
            emissionDate: true,
            supplier: true,
            operationalReference: false,
            totalOtherDocuments: true,
            totalInvoices: true,
            advance: true
        },
        withDetail: false,
        withReimbursement: true,
        withReimbursementAndDetails: false,
        requireAccountInfo: false
    },
    {
        name: "NOTA DE CRÉDITO PREIMPRESA",
        route: `${DOCUMENT_PATH}`,
        type: "printed-credit-note",
        inputs: {
            invoiceNumber: true,
            creditNoteNumber: true,
            authorizationNumber: true,
            invoiceDate: true,
            emissionDate: true,
            expiresDate: true,
            receptionDate: true,
            supplier: true,
            operationalReference: true,
            file: true
        },
        requireAccountInfo: true,
        withDetail: true
    },
]

export const DOCUMENT_LIST_TABLE_COLUMNS = [
    { id: FIELDS.invoiceNumber.key, label: FIELDS.invoiceNumber.name },
    { id: FIELDS.voucher.key, label: FIELDS.voucher.name },
    { id: FIELDS.emissionDate.key, label: FIELDS.emissionDate.name },
    { id: FIELDS.subtotalNoTaxes.key, label: FIELDS.subtotalNoTaxes.name },
    { id: FIELDS.discount.key, label: FIELDS.discount.name },
    { id: FIELDS.tip.key, label: FIELDS.tip.name },
    { id: FIELDS.taxesValue.key, label: FIELDS.taxesValue.name },
    { id: FIELDS.total.key, label: FIELDS.total.name }
]

export const DOCUMENT_ACTIONS = {
    reject: {
        id: 4,
        icon: 'block',
        name: "Rechazar",
        key: "canReject"
    }
}

export const DOCUMENT_MANAGE_ACTION_ID = -1
export const DOCUMENT_APPROVE_MANUALLY_ACTION_ID = -2
export const DOCUMENT_REJECT_MANUALLY_ACTION_ID = -3
export const DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID = -4

export const ADDITIONAL_CREDIT_NOTE_DOCUMENT_ACTIONS = {
    ignore: {
        id: 23,
        icon: 'remove_circle',
        name: "No procede",
        key: "canApprove"
    },
    manage: {
        id: DOCUMENT_MANAGE_ACTION_ID,
        icon: 'forward_to_inbox',
        name: "Gestionar",
        key: "canApprove"
    },
}

export const ADDITIONAL_RETENTION_DOCUMENT_ACTIONS = {
    manualApprove: {
        id: DOCUMENT_APPROVE_MANUALLY_ACTION_ID,
        icon: 'check_circle',
        name: "Aprobar Manualmente",
        key: "canApprove"
    },
    manualReject: {
        id: DOCUMENT_REJECT_MANUALLY_ACTION_ID,
        icon: 'block',
        name: "Rechazar Manualmente",
        key: "canApprove"
    },
}

export const ADDITIONAL_REIMBURSEMENT_DOCUMENT_ACTIONS = {
    send: {
        id: DOCUMENT_SEND_REIMBURSEMENT_ACTION_ID,
        icon: 'forward_to_inbox',
        name: "Enviar PDF Reembolso",
        key: "canApprove"
    }
}

export const DOCUMENTS_TABLE_COLUMNS = [
    { id: FIELDS.documentTypeId.key, label: FIELDS.documentTypeId.name },
    { id: FIELDS.serialNumber.key, label: FIELDS.serialNumber.name },
    { id: FIELDS.documentDate.key, label: FIELDS.documentDate.name },
    /* { id: FIELDS.authorizationDate.key, label: FIELDS.authorizationDate.name }, */
    { id: FIELDS.posting.key, label: FIELDS.posting.name },
    { id: FIELDS.companyRuc.key, label: FIELDS.companyRuc.name },
    { id: FIELDS.companyName.key, label: FIELDS.companyName.name },
    { id: FIELDS.flowStatus.key, label: FIELDS.flowStatus.name },
    { id: "error", label: "Error Validacion" },
]

export const SINGLE_COMPANY_DOCUMENTS_TABLE_COLUMNS = [
    { id: FIELDS.documentTypeId.key, label: FIELDS.documentTypeId.name },
    { id: FIELDS.serialNumber.key, label: FIELDS.serialNumber.name },
    { id: FIELDS.documentDate.key, label: FIELDS.documentDate.name },
    { id: FIELDS.authorizationDate.key, label: FIELDS.authorizationDate.name },
    { id: FIELDS.receiverRuc.key, label: FIELDS.receiverRuc.name },
    { id: FIELDS.receiverName.key, label: FIELDS.receiverName.name },
    { id: "error", label: "Error Validacion" },
]

export const FINISHED_PRINTED_INVOICES_COLUMNS = [
    { id: FIELDS.assignedPurchaseOrder.key, label: FIELDS.assignedPurchaseOrder.name },
    { id: FIELDS.retention.key, label: FIELDS.retention.name }
]

export const DEFAULT_DOCUMENT_FILTERS: IDocumentFilter = {
    conditions: [],
    documentRange: { end: null, start: null },
    receptionRange: { end: null, start: null },
    documentTypes: [],
    rucOrName: "",
    serialNumber: "",
    rucOrNameReceptor: "",
    historyTypes: []
}

export const DOCUMENT_TYPE_CODES = {
    electronicInvoice: "01",
    foreignExpensesLiquidation: "81",
    billOfLading: "82",
    printedInvoice: "71",
    purchaseSettlement: "03",
    retentionReceipt: "07",
    purchaseOrder: "91",
    reimbursementInvoice: "41",
    creditNote: "04",
    printedCreditNote: "74",
    cashReimbursement: "73",
    employeeReimbursement: "72",
    printedRetention: "77",
    companyReception: "companyReception",
    retentions: "retentions",
    allRetentions: "allRetentions",
    reimbursements: "reimbursements"
}

export const DOCUMENT_TYPE_IDS = {
    electronicInvoice: 1,
    creditNote: 5,
    retentionReceipt: 8,
    purchaseSettlement: 9,
    foreignExpensesLiquidation: 10,
    printedInvoice: 12,
    employeeReimbursement: 13,
    cashReimbursement: 14,
    printedCreditNote: 15,
    printedRetentionReceipt: 16,
    reimbursementInvoice: 41,
    alicuota: 19,
}

export const DOCUMENT_STATES = {
    pending: 5,
    finished: 6,
    authorized: 3,
    rejected: 4,
    entered: 1,
    sriEntered: 2,
    rejectedCOUPA: 18,
    rejectedSAT: 19,
    sriCanceled: 22,
    creditNoteCanceled: 24,
    inProcess: 25,
    accounted: 26,
    onHold: 27
}


export const ELECTRONIC_AUTHORIZATION_NUMBER_LIMIT = 49

export const AUTHORIZATION_NUMBER_SIZES = [10, 37, 49]

export const VOUCHER_MASK = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

export const VOUCHER_SIZE = 15