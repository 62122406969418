import React, { useEffect } from 'react'
import { DOCUMENT_PATH, DOCUMENT_STATES, DOCUMENT_TYPE_CODES, DOCUMENT_TYPE_IDS, DOCUMENT_VIEW_TITLE, getDocumentByIdService, getDocumentElectronicInfo, ROOT_PATH, ROOT_TITLE, updateDocumentAdditionalInfoService } from 'lib'
import { Helmet } from 'react-helmet'
import { DocumentBar, DocumentData, DetailData, ValuesData, EmissionData, ReceiverData, AdditionalData, DocumentFiles, DocumentEvents, AccountingData, WithholdingsData, RefundData, RejectionData, AssociatedData, ReimbursementData, ReimbursementValues, FixedLoadingIndicator } from 'components'
import { Divider, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { IDocumentView, IMessageConfig, IReimbursement } from 'types'
import { DocumentContext, ManagementContext } from 'context'
import { Alert } from '@material-ui/lab'
import EmissionDataFacturaVNC from 'components/EmissionData/EmissionDataFacturaVNC'
import DocumentDataFacturaVNS from 'components/DocumentData/DocumentDataFacturaVNS'
import ExcelLineComponent from 'components/ExcelLines/ExcelLineComponent'

const DocumentView = () => {
    const location = useLocation()
    const history = useHistory()
    const [documentView, setDocumentView] = React.useState<IDocumentView | undefined>(undefined)
    const { taxes, taxTypes } = React.useContext(ManagementContext)
    const { documentTypes } = React.useContext(DocumentContext)
    const [updating, setUpdating] = React.useState(false)
    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info", })
    const handleReload = async () => {
        // const id = location.pathname.split(ROOT_PATH).pop()
        // const result = await getDocumentByIdService(id)
        // if (documentView?.document.isManual) {
        //     setDocumentView({ ...result, additionalInfo: JSON.parse(result.additionalInfo) })
        // } else {
        //     setDocumentView({ ...result, additionalInfo: JSON.parse(result.document.additionalInfo) })
        // }
    }
    const handleUpdateAdditionaInfo = async (additionalInfo: string): Promise<boolean> => {
        try {
            setUpdating(true)
            await updateDocumentAdditionalInfoService(documentView?.document.documentId, additionalInfo)
            setMessageConfig({ open: true, message: "Información de documento actualizada", severity: "success" })
            setUpdating(false)
            return true
        } catch (error) {
            setMessageConfig({ open: true, message: "No se pudo actualizar la información del documento", severity: "error" })
            setUpdating(false)
            return false
        }
    }
    const isSpecialDetail = React.useCallback(() => {
        const type = documentTypes.find(d => d.documentTypeId === documentView?.document.documentTypeId && d.documentCode === DOCUMENT_TYPE_CODES.retentionReceipt)
        return documentView?.document?.documentTypeId === type?.documentTypeId
    }, [documentTypes, documentView])

    React.useEffect(() => {
        const init = async () => {
            if ((location.state as any)?.document) {
                console.log((location.state as any)?.document);
                if ((location.state as any)?.document?.document?.isManual && (location.state as any)?.document?.document.documentTypeId !== 19) {
                    setDocumentView((location.state as any)?.document)
                } else {
                    setDocumentView(getDocumentElectronicInfo((location.state as any)?.document, (location.state as any)?.document?.electronicData, taxes, taxTypes, documentTypes))
                }
            } else {
                const id = location.pathname.split(ROOT_PATH).pop()
                try {
                    const result = await getDocumentByIdService(id)
                    setDocumentView({ ...result, additionalInfo: JSON.parse(result.additionalInfo) })
                } catch (error) {
                    history.replace(`${DOCUMENT_PATH}`)
                }
            }
        }
        init()
    }, [location, history, taxes, taxTypes, documentTypes])
    const textStyle = { fontWeight: 600, fontSize: "0.8em" }
    const headerCellStyle = { fontSize: "0.75em", fontWeight: 600, letterSpacing: 0.2 }
    const bodyCellStyle = { fontSize: "0.8em", letterSpacing: 0.2 }


    return (
        <div className="w-full flex flex-col h-full bg-white document-primary-outline">
            <Helmet>
                <title>{`${ROOT_TITLE} - ${DOCUMENT_VIEW_TITLE}`}</title>
            </Helmet>
            {
                documentView &&
                <React.Fragment>
                    <DocumentBar
                        documentId={documentView.document.documentId}
                        documentData={documentView.data}
                        supplierId={documentView.data?.header.supplier.supplierId}
                        processStatus={documentView.document.flowStatus}
                        documentType={documentTypes.find(d => d.documentTypeId === documentView.document?.documentTypeId)?.documentName}
                        onReload={handleReload}
                        isManual={documentView.document.isManual}
                        header={documentView.data?.header}
                        documentTypeId={documentView.document.documentTypeId}
                        redirectOnApprove={(location.state as any)?.redirectOnApprove}
                        redirectOnReject={(location.state as any)?.redirectOnReject}
                        aditionalInfo={documentView.document.additionalInfo}
                        validateReimbusements={documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice ? JSON.stringify((documentView.refunds?.map(r => r.authorizationDoc) ?? [])) === JSON.stringify(((JSON.parse(documentView.document.additionalInfo) ?? [])?.reimbursements?.map(r => r.authorizationNumber) ?? [])) : undefined}
                    />
                    <Divider style={{ backgroundColor: "#eee", marginLeft: 3 }} />
                    {!Boolean(documentView.retention) &&
                        <div className="overflow-y-auto">
                            {
                                Boolean(
                                    documentView.document.isManual && documentView.document?.documentTypeId !== 19 ?
                                        documentView.data.rejectionCause :
                                        JSON.parse(documentView.document.additionalInfo)?.rejectionCause
                                ) &&
                                <div className="px-8 flex w-full mt-2 items-start">
                                    <RejectionData
                                        rejectionCause={documentView.document.isManual && documentView.document?.documentTypeId !== 19 ?
                                            documentView.data.rejectionCause :
                                            JSON.parse(documentView.document.additionalInfo)?.rejectionCause
                                        }
                                        status={documentView.document.flowStatus}
                                        type={1}
                                    />
                                </div>
                            }
                            <div className="flex w-full mt-2 items-start">
                                <div className="w-2/4 py-4 px-8">
                                    {
                                        documentView.data && Boolean(documentView.retention) &&
                                        <EmissionData
                                            header={documentView.data.header}
                                        />
                                    }
                                    {
                                        !Boolean(documentView.retention) &&
                                        <EmissionDataFacturaVNC
                                            document={documentView.document}
                                        />
                                    }
                                    <ReceiverData
                                        identification={documentView.document.receiverRuc}
                                        name={documentView.document.receiverName}
                                        isManual={documentView.document.isManual}
                                    />
                                </div>
                                <div className="w-2/4 py-4 pr-8 h-full">
                                    {
                                        documentView.data && Boolean(documentView.retention) &&
                                        <DocumentData
                                            header={documentView.data.header}
                                            isManual={documentView.document.isManual}
                                        />
                                    }
                                    {
                                        !Boolean(documentView.retention) &&
                                        <DocumentDataFacturaVNS document={documentView.document} lines={documentView.excellines!}
                                        />

                                    }
                                </div>
                            </div>
                            {
                                documentView.data &&
                                <div className="flex w-full mt-2">
                                    <DetailData
                                        detail={documentView.data?.detail}
                                        specialDetail={isSpecialDetail()}
                                    />
                                </div>
                            }
                            {
                                documentView.refunds &&
                                <div className="flex w-full mt-2 flex-col px-8">
                                    <RefundData
                                        skipAuthorizationValidation={documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice}
                                        readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                        refunds={documentView.refunds}
                                        associated={documentView.document.associated}
                                        files={documentView.files}
                                        onAddDocumentFiles={(newFiles) => setDocumentView(current => {
                                            if (current) { return { ...current, files: [...current.files].concat(newFiles) } }
                                            return current
                                        })}
                                        documentId={documentView.document.documentId}
                                        edit
                                        onAddReimbusement={async (newReimbursement) => {
                                            const newAdditionalInfo = JSON.parse(documentView.document.additionalInfo)
                                            if (documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice) {
                                                if (!await handleUpdateAdditionaInfo(JSON.stringify({ ...newAdditionalInfo, reimbursements: newAdditionalInfo.reimbursements ? [...newAdditionalInfo.reimbursements, newReimbursement] : [newReimbursement] }))) {
                                                    return
                                                }
                                            }
                                            setDocumentView((current) => {
                                                if (current) {
                                                    if (current.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice) {
                                                        return {
                                                            ...current,
                                                            document: {
                                                                ...current.document,
                                                                additionalInfo: JSON.stringify({
                                                                    ...newAdditionalInfo,
                                                                    reimbursements: newAdditionalInfo.reimbursements ? [...newAdditionalInfo.reimbursements, newReimbursement] : [newReimbursement]
                                                                })
                                                            }
                                                        }
                                                    }
                                                    return {
                                                        ...current,
                                                        data: {
                                                            ...current.data,
                                                            reimbursements: current.data.reimbursements ? [...current.data.reimbursements, newReimbursement] : [newReimbursement]
                                                        }
                                                    }
                                                }
                                                return undefined
                                            })
                                        }}
                                    />
                                </div>
                            }
                            {
                                Boolean(
                                    documentView.document.isManual ?
                                        documentView.data.reimbursements && documentView.data.reimbursements.length > 0 :
                                        (JSON.parse(documentView.document.additionalInfo)?.reimbursements || (documentView.data.reimbursements && documentView.data.reimbursements.length > 0))
                                ) &&
                                <div className="flex w-full mt-2">
                                    <ReimbursementData
                                        onEdit={(edited) => setDocumentView(current => {
                                            if (current) {
                                                return ({ ...current, data: { ...current.data, reimbursements: edited } })
                                            }
                                        })}
                                        reimbursements={
                                            documentView.document.isManual ?
                                                documentView.data.reimbursements :
                                                (JSON.parse(documentView.document.additionalInfo)?.reimbursements || documentView.data.reimbursements)
                                        }
                                        withDetails={documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement}
                                        readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                        files={documentView.files}
                                        associated={documentView.document.associated}
                                        documentId={documentView.document.documentId}
                                        onAddDocumentFiles={(newFiles) => setDocumentView(current => {
                                            if (current) { return { ...current, files: [...current.files].concat(newFiles) } }
                                            return current
                                        })}
                                        withExpenses={documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement || documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement}
                                        withDelete={documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice}
                                        onDelete={async (deleted) => {
                                            const newAdditionalInfo = JSON.parse(documentView.document.additionalInfo)
                                            if (documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice) {
                                                if (!await handleUpdateAdditionaInfo(JSON.stringify({ ...newAdditionalInfo, reimbursements: ((newAdditionalInfo.reimbursements ?? []) as IReimbursement[]).filter(r => r.authorizationNumber + r.documentNumber !== deleted.authorizationNumber + deleted.documentNumber) }))) {
                                                    return
                                                }

                                            }
                                            setDocumentView(current => {
                                                if (current) {
                                                    return {
                                                        ...current,
                                                        document: {
                                                            ...current.document,
                                                            additionalInfo: JSON.stringify({ ...newAdditionalInfo, reimbursements: ((newAdditionalInfo.reimbursements ?? []) as IReimbursement[]).filter(r => r.authorizationNumber + r.documentNumber !== deleted.authorizationNumber + deleted.documentNumber) })
                                                        }
                                                    }
                                                }
                                                return undefined
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="flex w-full">
                                <div className="w-2/4 py-4 px-8">
                                    {
                                        (Boolean(documentView.additionals) && documentView.additionals.length > 0) &&
                                        <AdditionalData
                                            additionals={documentView.additionals}
                                        />
                                    }
                                    <DocumentFiles
                                        files={documentView.files}
                                        documentId={documentView.document.documentId}
                                        onAddDocumentFiles={(newFiles) => setDocumentView(current => {
                                            if (current) { return { ...current, files: [...current.files].concat(newFiles) } }
                                            return current
                                        })}
                                        onDeleteDocumentFile={(deletedFile) => setDocumentView(current => {
                                            if (current) { return { ...current, files: [...current.files].filter(f => f.documentFileId !== deletedFile.documentFileId) } }
                                            return current
                                        })}
                                        readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                    />
                                </div>
                                {documentView.excellines != null && documentView.excellines.length > 0 &&
                                    <>
                                        <ExcelLineComponent lines={documentView.excellines} retentions={documentView.retentionsFiles!}></ExcelLineComponent>
                                    </>

                                }
                                {/* {
                                    (documentView.data && (documentView.document.documentTypeId !== DOCUMENT_TYPE_IDS.alicuota)) &&
                                    <div className="w-2/4 py-4 pr-8">
                                        <ValuesData
                                            data={documentView.data}
                                            detail={documentView.data.detail}
                                            showInputs={Boolean(documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.electronicInvoice && documentView.document.flowStatus === DOCUMENT_STATES.pending)}
                                            showAdditionalInfo={Boolean(documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.electronicInvoice && documentView.document.flowStatus === DOCUMENT_STATES.finished)}
                                            additionalInfo={documentView.document.additionalInfo}
                                            supplierId={documentView.document.companyRuc}
                                            documentId={documentView.document.documentId}
                                        />
                                    </div>
                                } */}
                                {
                                    (documentView.data && documentView.data.header.reimbursementSummary && (documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.reimbursementInvoice || documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.cashReimbursement || documentView.document.documentTypeId === DOCUMENT_TYPE_IDS.employeeReimbursement)) &&
                                    <div className="w-2/4 py-4 pr-8">
                                        <ReimbursementValues
                                            reimbursementSummary={documentView.data.header.reimbursementSummary}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex w-full mt-2">
                                <DocumentEvents
                                    events={documentView.historyData}
                                />
                            </div>
                            <div className="flex w-full mt-2">
                                <AccountingData
                                    accountInfo={documentView.document.isManual ? documentView.data.accountInfo : documentView.document.additionalInfo ? JSON.parse(documentView.document.additionalInfo as string).accountInfo : undefined}
                                    readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                />
                            </div>
                            {
                                documentView.retentions.length > 0 &&
                                <div className="flex w-full mt-2">
                                    <WithholdingsData retentions={documentView.retentions} />
                                </div>
                            }
                            {
                                documentView.document.associated.length > 0 &&
                                <div className="flex w-full mt-2">
                                    <AssociatedData associated={documentView.document.associated} />
                                </div>
                            }
                        </div>
                    }
                    {documentView.retention &&
                        <>
                            <div className="overflow-y-auto">
                                {
                                    Boolean(
                                        documentView.document.isManual && documentView.document?.documentTypeId !== 19 ?
                                            documentView.data.rejectionCause :
                                            JSON.parse(documentView.document.additionalInfo)?.rejectionCause
                                    ) &&
                                    <div className="px-8 flex w-full mt-2 items-start">
                                        <RejectionData
                                            rejectionCause={documentView.document.isManual && documentView.document?.documentTypeId !== 19 ?
                                                documentView.data.rejectionCause :
                                                JSON.parse(documentView.document.additionalInfo)?.rejectionCause
                                            }
                                            status={documentView.document.flowStatus}
                                            type={7}
                                        />
                                    </div>
                                }
                                <div className="flex w-full mt-2 items-start">
                                    <div className="w-2/4 py-4 px-8">
                                        <>
                                            <Paper variant="outlined" className="p-4 px-6" elevation={1} style={{ marginBottom: '30px' }}>
                                                <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                    {"DATOS DEL EMISOR"}
                                                </Typography>
                                                <div className="mt-2">
                                                    <div className="my-2">
                                                        <Divider />
                                                    </div>
                                                    <React.Fragment>
                                                        <div className="flex mb-3 items-center">
                                                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                                {'COMPAÑIA'}
                                                            </Typography>
                                                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                                                {documentView.retention.emisionData.razonSocialAgenteRetencion}
                                                            </Typography>
                                                        </div>
                                                        <div className="flex mb-3 items-center">
                                                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                                {'IDENTIFICACION'}
                                                            </Typography>
                                                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                                                {documentView.retention.emisionData.identificacionAgenteRetencion}
                                                            </Typography>
                                                        </div>
                                                        <div className="flex">
                                                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                                {`DIRECCION:`}
                                                            </Typography>
                                                            <Typography style={textStyle} className="pl-2" variant="subtitle2">
                                                                {documentView.retention.emisionData.direccionAgenteRetencion}
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            </Paper>
                                            <Paper variant="outlined" className="p-4 px-6 mt-3" elevation={1}>
                                                <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                    {"DATOS DEL RECEPTOR"}
                                                </Typography>
                                                <div className="mt-2">
                                                    <div className="my-2">
                                                        <Divider />
                                                    </div>
                                                    <React.Fragment>
                                                        <div className="flex mb-3 items-center">
                                                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                                {'RAZON SOCIAL SUJETO RETENIDO'}
                                                            </Typography>
                                                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                                                {documentView.retention.receptionData.razonSocialSujetoRetenido}
                                                            </Typography>
                                                        </div>
                                                        <div className="flex">
                                                            <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                                {'IDENTIFICACION SUJETO RETENIDO'}
                                                            </Typography>
                                                            <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                                                {documentView.retention.receptionData.identificacionSujetoRetenido}
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            </Paper>
                                        </>
                                    </div>
                                    <div className="w-2/4 py-4 pr-8 h-full">
                                        <Paper variant="outlined" className="p-4 px-6" elevation={1}>
                                            <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                {"DATOS DEL DOCUMENTO"}
                                            </Typography>
                                            <div className="mt-2">
                                                <div className="my-2">
                                                    <Divider />
                                                </div>
                                                <div className="flex mb-3 items-center">
                                                    <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                        {'NUMERO DE COMPROBANTE:'}
                                                    </Typography>
                                                    <Typography style={textStyle} className="pl-2" variant="subtitle2">
                                                        {documentView.retention.emisionData.numeroComprobanteRetencion}
                                                    </Typography>
                                                </div>
                                                <div className="flex mb-3 items-center">
                                                    <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                        {'FECHA:'}
                                                    </Typography>
                                                    <Typography style={textStyle} className="pl-2" variant="subtitle2">
                                                        {documentView.retention.emisionData.fecha}
                                                    </Typography>
                                                </div>
                                                <div className="flex">
                                                    <Typography style={textStyle} color="textSecondary" variant="subtitle2">
                                                        {'PERIODO FISCAL'}
                                                    </Typography>
                                                    <Typography style={textStyle} className="pl-5" variant="subtitle2">
                                                        {documentView.retention.emisionData.periodoFiscal}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Paper>
                                    </div>
                                </div>
                                {documentView.retention.ivaDetails.length !== 0 &&
                                    <div className="flex w-full mt-2">
                                        <div className="px-8 w-full">
                                            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                                                <div className="py-3 px-4 flex w-full items-center">
                                                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                        {"RETENCION IVA"}
                                                    </Typography>
                                                </div>
                                                <Divider />
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={headerCellStyle} align="left">{"Oper. Nro."}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Fecha Factura"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"# Comprobante"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"# Factura"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"# Nota Credito"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"# Nota Debito"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Control Factura"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Tipo Transaccion"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Factura Afecatada"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Compras Incluido IVA"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Compras Credito IVA"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Base Imponible"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"% Alicuota"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Impuesto IVA"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"IVA Retenido"}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                documentView.retention.ivaDetails.map(ivaLine => (
                                                                    <TableRow>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.operacionNumero}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.fechaFactura}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroDeComprobante}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroDeFactura}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroDeNotaCredito}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroDeNotaDebito}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroControlFactura}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.tipoTransaccion}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.numeroFacturaAfectada}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.totalComprasIncluyendoIVA}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.comprasSinDerechoACreditoIVA}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.baseImponible}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.porcentajeAlicuota}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.impuestoIVA}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{ivaLine.ivaRetenido}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                }
                                {documentView.retention.rentaDetails.length !== 0 &&
                                    <div className="flex w-full mt-2" style={{ marginTop: '20px' }}>
                                        <div className="px-8 w-full">
                                            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                                                <div className="py-3 px-4 flex w-full items-center">
                                                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                        {"RETENCION RENTA"}
                                                    </Typography>
                                                </div>
                                                <Divider />
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={headerCellStyle} align="left">{"Fecha de Pago"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Factura"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"# de Control"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Monto del Pago"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Monto Sujeto Retencion"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"%"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Impuesto Retenido"}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                documentView.retention.rentaDetails.map(rentaLine => (
                                                                    <TableRow>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.fechaPago}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.factura}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.numeroDeControl}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.montoDelPago}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.montoSujetoARetencion}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.porcentaje}</TableCell>
                                                                        <TableCell style={bodyCellStyle} align="left">{rentaLine.impuestoRetenido}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                }
                                {documentView.retention.recalculo.recalculo != null &&
                                    <div className="flex w-full mt-2" style={{ marginTop: '20px' }}>
                                        <div className="w-2/4 py-4 px-8">
                                            <Paper variant="outlined" className="mt-3 w-full" elevation={1}>
                                                <div className="py-3 px-4 flex w-full items-center">
                                                    <Typography style={{ fontWeight: 600, letterSpacing: 0.5, color: "#b1b1b1" }} variant="caption">
                                                        {"RECALCULO APLICADO"}
                                                    </Typography>
                                                </div>
                                                <Divider />
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={headerCellStyle} align="left">{"Valor Recalculo"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Valor Excel"}</TableCell>
                                                                <TableCell style={headerCellStyle} align="left">{"Diferencia"}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={bodyCellStyle} align="left">{documentView.retention.recalculo.recalculo}</TableCell>
                                                                <TableCell style={bodyCellStyle} align="left">{documentView.retention.recalculo.excelLines}</TableCell>
                                                                <TableCell style={bodyCellStyle} align="left">{documentView.retention.recalculo.diference}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                    </div>
                                }
                                <div className="flex w-full mt-2">
                                    <div className="w-2/4 py-4 px-8">
                                        <DocumentFiles
                                            files={documentView.files}
                                            documentId={documentView.document.documentId}
                                            onAddDocumentFiles={(newFiles) => setDocumentView(current => {
                                                if (current) { return { ...current, files: [...current.files].concat(newFiles) } }
                                                return current
                                            })}
                                            onDeleteDocumentFile={(deletedFile) => setDocumentView(current => {
                                                if (current) { return { ...current, files: [...current.files].filter(f => f.documentFileId !== deletedFile.documentFileId) } }
                                                return current
                                            })}
                                            readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full mt-2">
                                    <DocumentEvents
                                        events={documentView.historyData}
                                    />
                                </div>
                                <div className="flex w-full mt-2">
                                    <AccountingData
                                        accountInfo={documentView.document.isManual ? documentView.data.accountInfo : documentView.document.additionalInfo ? JSON.parse(documentView.document.additionalInfo as string).accountInfo : undefined}
                                        readOnly={documentView.document.flowStatus === DOCUMENT_STATES.finished || documentView.document.flowStatus === DOCUMENT_STATES.rejected}
                                    />
                                </div>
                                {
                                    documentView.retentions.length > 0 &&
                                    <div className="flex w-full mt-2">
                                        <WithholdingsData retentions={documentView.retentions} />
                                    </div>
                                }
                                {
                                    documentView.document.associated.length > 0 &&
                                    <div className="flex w-full mt-2">
                                        <AssociatedData associated={documentView.document.associated} />
                                    </div>
                                }
                            </div>

                        </>

                    }
                    <FixedLoadingIndicator loading={updating} />
                    <Snackbar
                        open={messageConfig.open}
                        autoHideDuration={6000}
                        onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                        <Alert
                            variant="filled"
                            onClose={() => setMessageConfig({ ...messageConfig, open: false })}
                            severity={messageConfig.severity}
                        >
                            {messageConfig.message}
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            }
        </div>
    )
}

export default DocumentView
